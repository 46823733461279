import React from 'react';
import styles from './LeadServicesSection.module.css'; // The CSS module for styling

const ProcessSection = () => {
  return (
    <section className={styles.leadSection}>
      <div className={styles.headerTextContainer} data-aos="fade-right" data-aos-delay="300">
        <div className={styles.leadHeading}>Qualified leads in your diary</div>
        <div className={styles.leadHeading}>Or you don't pay</div>
      </div>
      <div className={styles.leadContainer}>
        <div className={styles.leadItem} data-aos="fade-up" data-aos-delay="600">
          <img src="/ContentImage.png" alt="Discovery" className={styles.leadImage} />
          <div className={styles.leadHeader}>Gather Content.</div>
          <div className={styles.leadText}>We collect or create visuals that highlight your product or service. These form the foundation of our strategy, serving to attract a greater number of potential clients to your business.</div>
        </div>
        <div className={styles.leadItem} data-aos="fade-up" data-aos-delay="600">
          <img src="/LaunchImage.png" alt="Improve" className={styles.leadImage} />
          <div className={styles.leadHeader}>Launch Ads.</div>
          <div className={styles.leadText}>Next, we launch advertisements based on the content we've collected. These ads are strategically designed and placed on popular social media platforms to capture clients' attention.</div>
        </div>
        <div className={styles.leadItem} data-aos="fade-up" data-aos-delay="600">
          <img src="/QualifiedImage.png" alt="Content OS" className={styles.leadImage} />
          <div className={styles.leadHeader}>Qualify Leads.</div>
          <div className={styles.leadText}>Finally, we collect information from individuals who are genuinely interested in your services. Prioritising quote-ready leads and eliminating tire kickers.</div>
        </div>
      </div>
    </section>
  );
};

export default ProcessSection;
