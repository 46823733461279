import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from './components/Navbar';
import MainBanner from './components/MainBanner';
import ServicesSection from './components/ServicesSection';
import LeadServicesSection from './components/LeadServicesSection';
import PriceSection from './components/PriceSection';
import AboutUsSection from './components/AboutUsSection';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1600, 
      once: false, 
      mirror: true,
    });
  }, []);

  return (
    <div>
      <Navbar />
      <main>
        <div id="home"><MainBanner /></div>
        <div id="leader"><ServicesSection /></div>
        <div id="leads"><LeadServicesSection /></div>
        <div id="prices"><PriceSection /></div>
        <div id="about"><AboutUsSection /></div>
        <div id="contact"><ContactForm /></div>
        <Footer />
      </main>
    </div>
  );
};

export default App;
