import React, { useEffect, useRef } from 'react';
import styles from './ServicesSection.module.css';

const ProcessSection = () => {
  const processItemRefs = useRef([]);

  useEffect(() => {
    const currentRefs = processItemRefs.current;

    const calculateRootMargin = () => {
      const viewportHeight = window.innerHeight;
      const itemHeight = 200; 
      return `-${(viewportHeight / 2) - (itemHeight / 2)}px 0px`;
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.fadeIn);
            entry.target.classList.remove(styles.fadeOut);
          } else {
            entry.target.classList.remove(styles.fadeIn);
            entry.target.classList.add(styles.fadeOut);
          }
        });
      },
      {
        rootMargin: calculateRootMargin(),
        threshold: 0.1, 
      }
    );

    currentRefs.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      currentRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []); 

  const processItems = [
    'Want to grow your Industry Presence?',
    'But find Social Media draining',
    'Struggle to find time',
    'Afraid it will become a distraction',
    'We take away the pain of...',
    'Self-Publishing',
    'Networking Online',
    'Self-Promotion',
    'Community Development',
    'Social Outreach',
    'Personal Branding',
  ];

  return (
    <section className={styles.processSection}>
      <div className={styles.processContainer}>
        {processItems.map((item, index) => (
          <div
            ref={(el) => (processItemRefs.current[index] = el)} 
            key={index}
            className={styles.processItem}
          >
            {item}
          </div>
        ))}
        <div className={styles.cta} data-aos="fade-up">
          <div className={styles.ctaItem} >Become An Industry Leader</div>
          <div className={styles.ctaItem}>Don’t Let Your Story Go to Waste</div>
          <div className={styles.findOutButton}>
            <a
              href="https://cal.com/founder-brandaroundyou/introduction"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.button}
            >
              Find Out How
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcessSection;
