import React from 'react';
import styles from './Footer.module.css'; // Import the CSS module for styling
import { FaTwitter, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.socialLinks}>
        <a href="https://x.com/brandaroundyou" target="_blank" rel="noopener noreferrer">
          <FaTwitter className={styles.socialIcon} />
          </a>
        <a href="https://www.linkedin.com/company/brand-around-you/" target="_blank" rel="noopener noreferrer">
          <FaLinkedinIn className={styles.socialIcon} />
        </a>
        </div>
        <div className={styles.businessDetails}>
          <h3>contactus@brandaroundyou.com</h3>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
