import React from 'react';
import styles from './PriceSection.module.css';

const PricingSection = () => {

  const handleCeoClick = () => {
    window.open('https://cal.com/founder-brandaroundyou/introduction', '_blank');
  };

  return (
    <section className={styles.pricingSection}>
      <img src="/PriceImage.png" alt="Boutique Agency, Walmart Price" className={styles.headerImage} data-aos="flip-up"/>
      <div className={styles.pricingContainer}>
        <div className={styles.pricingItem}  data-aos="flip-right" data-aos-delay="600">
         <h2>Sample Founder Program Plan</h2>
          <h3>£799 per month.</h3>
          <ul>
            <li>4 content calls.</li>
            <li>12 Social Posts across up to 3 platforms.</li>
            <li>Social Platform management up to 3 platforms.</li>
            <li>Social Platform improvement analysis and implementation.</li>
            <li>20 hours of social presence.</li>
            <li>Rolling Monthly Contract - Cancel anytime.</li>
          </ul>
          <div className={styles.cta}>
        <p className={styles.toGetStartedText}>To get started:</p>
        <button className={styles.ctaButton} onClick={handleCeoClick}>Get a Custom Quote</button>
      </div>
        </div>
        <div className={styles.pricingItem} data-aos="flip-left" data-aos-delay="600">
        <h2>Sample Qualified Leads Plan</h2>
          <h3>£1500 per month Ad Spend</h3>
          <h3>£1000 per month Service Charge</h3>
          <ul>
            <li>A Qualified Leads Guarantee.</li>
            <li>Pay £0 Service Charge if we don't deliver.</li>
            <li>Ad Creative and Copywriting</li>
            <li>Lead Qualifying and Appointment Setting.</li>
            <li>Rolling Monthly Contract - Cancel anytime.</li>
          </ul>
          <div className={styles.cta}>
        <p className={styles.toGetStartedText}>To get started:</p>
        <button className={styles.ctaButton} onClick={handleCeoClick}>Get a Custom Quote</button>
      </div>
        </div>

      </div>
    </section>
  );
};

export default PricingSection;