import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './MainBanner.module.css';

const MainBanner = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };

  const slides = [
    `/page1.jpg`, 
    `/page2.jpg`,
    `/page3.jpg`, 
    `/page4.jpg`,
    `/page5.jpg`, 
    `/page6.jpg`,
    `/page7.jpg`, 
    `/page8.jpg`,
    `/page9.jpg`, 
    `/page10.jpg`,
    `/page11.jpg`, 
    `/page12.jpg`,
    `/page13.jpg`, 
    `/page14.jpg`,
    `/page15.jpg`,
  ];

  return (
    <div className={styles.mainBanner} data-aos="fade-up" data-aos-delay="600">
      <div className={styles.neonSign}>
        <img src={`/OFF.png`} alt="Brand Around You Off" className={styles.overlayImage} />
        <img src={`/ON.png`} alt="Brand Around You Words On" className={`${styles.overlayImage} ${styles.handsOn}`} />
      </div>
      <div className={styles.carouselWrapper}>
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index} className={styles.slide}>
              <img src={slide} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className={styles.meetCeoButton}>
        <a
          href="https://cal.com/founder-brandaroundyou/introduction"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.button}
        >
          Meet With Us
        </a>
      </div>
    </div>
    
  );
};

export default MainBanner;