// Navbar.js
import React, { useState } from 'react';
import styles from './Navbar.module.css';
import SocialTicker from './SocialTicker';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleNavLinkClick = (event, id) => {
    if (id !== 'login') {
      event.preventDefault();
      const sectionId = event.currentTarget.getAttribute('href');
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleLoginClick = () => {
    window.open('https://billing.stripe.com/p/login/7sIg1OfNWalrftK5kk', '_blank');
  };

  const handleCeoClick = () => {
    window.open('https://cal.com/founder-brandaroundyou/introduction', '_blank');
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarLeft}>
        <SocialTicker />
      </div>
      <div className={styles.navbarRight}>
        <div className={isOpen ? styles.navLinksOpen : styles.navLinks}>
          <a  href="#home" onClick={handleNavLinkClick} className={styles.navLinkLogo}><img src="/smallLogo.png" alt="Small Brand Around You Logo"></img></a>
          <a href="#leader" onClick={handleNavLinkClick} className={styles.navLink}>Leader Program</a>
          <a href="#leads" onClick={handleNavLinkClick} className={styles.navLink}>Qualified Leads</a>
          <a href="#prices" onClick={handleNavLinkClick} className={styles.navLink}>Sample Pricing</a>
          <a href="#about" onClick={(e) => handleNavLinkClick(e, 'about')} className={styles.navLink}>About Us</a>
          <button onClick={handleCeoClick} className={`${styles.navLink} ${styles.ceoNavButton}`}>Meet With Us</button>
          <button onClick={handleLoginClick} className={`${styles.navLink} ${styles.loginButton}`}>Login</button>
        </div>
        <button onClick={toggleMenu} className={styles.burgerMenuButton} aria-label="Menu">
        <img src="/BurgerMenu.png" alt="Brand Around You" />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
