// SocialTicker.js
import React from 'react';
import styles from './SocialTicker.module.css';

const socialMedia = [
  { logo: '/Xlogo.png', handle: '@brandaroundyou', url: 'https://www.twitter.com/brandaroundyou' },
  { logo: '/LinkedinLogo.png', handle: '@brandaroundyou', url: 'https://www.linkedin.com/company/brand-around-you/' },
];

const SocialTicker = () => {
  return (
    <div className={styles.socialTicker}>
      {socialMedia.map((media, index) => (
        <a key={index} href={media.url} className={styles.socialItem}>
          <img src={media.logo} alt={`${media.handle} logo`} />
          <span>{media.handle}</span>
        </a>
      ))}
    </div>
  );
};

export default SocialTicker;
