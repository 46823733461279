import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import styles from './ContactForm.module.css'; 

const ContactForm = () => {
  const googleMapSrc = `https://www.google.com/maps/embed/v1/place?key=AIzaSyA43rIEoFikjU7dpp04eA1sxXMFEvXfr0I&q=Kingsclere,Hampshire,England`;
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', and 'YOUR_USER_ID' with your actual EmailJS values
    emailjs.sendForm('service_mkobh1v', 'template_qkw4m3i', form.current, 'L41LywahkDmk3irkp')
      .then((result) => {
          console.log(result.text);
          alert('Message sent! We will be in touch shortly.');
          // Optionally reset the form here
          e.target.reset();
      }, (error) => {
          console.log(error.text);
          alert('An error occurred. Please try again.');
      });
  };
  return (
    <section className={styles.contactFormContainer}>
      <div className={styles.formSection} data-aos="flip-left" data-aos-delay="600">
      <h2>Have a query?</h2>
      <h3>Then please send us a message:</h3>
      <form className={styles.contactForm} ref={form} onSubmit={handleSubmit} name="contact">
      <label htmlFor="full-name">Full Name*</label>
      <input type="text" id="full-name" name="full-name" autoComplete="name" required />

      <label htmlFor="email">E-Mail*</label>
      <input type="email" id="email" name="email" autoComplete="email" required />

      <label htmlFor="company">Company*</label>
      <input type="text" id="company" name="company" autoComplete="organization" required />

      <label htmlFor="help">How can we help?*</label>
      <textarea id="help" name="help" autoComplete="off" required></textarea>

      <button type="submit" className={styles.contactButton}>Send Message</button>
    </form>
    </div>
    <div className={styles.mapSection} data-aos="flip-right" data-aos-delay="600">
    <h2>We are located in:</h2>
      <h3>Kingsclere, Hampshire, UK.</h3>
        <iframe
          title="Google Map of Kingsclere"
          src={googleMapSrc}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
};

export default ContactForm;
