// AboutUsSection.js
import React from 'react';
import styles from './AboutUsSection.module.css'; // Ensure you have the corresponding CSS module for styling

const AboutUsSection = () => {

  const handleCeoClick = () => {
    window.open('https://cal.com/ceo-brandaroundyou/introduction', '_blank');
  };

  return (
    <section className={styles.aboutUsSection}>
      <div className={styles.ceoSection} data-aos="zoom-in" data-aos-delay="600">
        <h2 className={styles.title}>Mathew Evans</h2>
        <p>Founder</p>
        <img src="/CEOImage.png" alt="CEO profile" className={styles.ceoImage} />
        <p className={styles.ceoBio}>
        My fascination with advertising and marketing took root in my early teens. A journey that started with advertising memorabilia and was further ignited by the rise of the notorious AMC TV series Mad Men. This initial spark led me to delve deeper, during my higher education, into the intricate blend of creativity and psychology that characterizes the advertising world. Rory Sutherland and Simon Squibb have been a significant source of inspiration for me. Alongside my passion for marketing, I am an avid tech enthusiast and enjoy the pleasure of walking my dog.
        </p>
        <button onClick={handleCeoClick} className={styles.ctaButton}>Meet With Me</button>
      </div>
      <div className={styles.purposeMissionSection}>
        <img src="/AboutUsImage.png" alt="We have purpose, we have mission" className={styles.purposeMissionImage}  data-aos="flip-up" data-aos-delay="600"/>
        <div className={styles.purposeBlock} data-aos="zoom-in-down" data-aos-delay="600">
          <h3 className={styles.subtitle}>Our Purpose</h3>
          <p className={styles.text}>
          We believe every business is a gold mine for content and that most of these content gold mines go untapped or utilized minimally. </p><p>Our purpose is to mine this content gold.</p><p> 
          We believe that by implementing systems, things happen systematically, and this includes your brand. </p><p>Our purpose is to implement systems that systematically grow brands. 
          </p>
        </div>
        <div className={styles.missionBlock} data-aos="zoom-in-down" data-aos-delay="600">
          <h3 className={styles.subtitle}>Mission Statement</h3>
          <p className={styles.text}>
          We are a for-profit company that does good.
          </p>   <p className={styles.text}>
We commit to inspiring the current and next generation of business leaders to create a brand around them and their product.
</p>  <p className={styles.text}>
We pledge 10% of Brand Around You profits go towards our 'Young Person's Business Award' where we will give £10,000 to aspiring entrepreneurs, allowing them to fund their dream business.     
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
